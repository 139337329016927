import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addItem } from "../redux/actions/cartActions";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Products = () => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(data);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  const dispatch = useDispatch();

  const addProduct = (product) => {
    dispatch(addItem(product));
  };

  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/api/products`);
        setData(response.data);
        setFilter(response.data);
        setLoading(false);
        setCategories(
          response.data
            .map((item) => item.category)
            .filter((value, index, self) => self.indexOf(value) === index)
        );
      } catch (error) {
        setLoading(false);
      }
    };
    getProducts();
  }, []);

  const Loading = () => (
    <>
      <div className="col-12 py-5 text-center">
        <Skeleton height={40} width={560} />
      </div>
      {Array(6)
        .fill()
        .map((_, index) => (
          <div key={index} className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
            <Skeleton height={592} />
          </div>
        ))}
    </>
  );

  const filterProduct = (cat) => {
    const updatedList = data.filter((item) => item.category === cat);
    setFilter(updatedList);
  };

  const ShowProducts = () => (
    <>
      <div className="relative my-10">
        <button
          className="absolute left-0 z-10 top-1/2 transform -translate-y-1/2 bg-lime-600 text-white p-2 rounded-full shadow-lg flex items-center justify-center"
          onClick={scrollLeft}
        >
          <span className="material-symbols-outlined ml-1">arrow_back_ios</span>
        </button>

        {/* Horizontal Scroll Container */}
        <div
          id="latest-products"
          className="flex space-x-4 overflow-x-auto w-full scrollbar-hide px-6 py-4"
          style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
        >
          {filter.slice(Math.max(filter.length - 6, 0)).map((product) => (
            <Link
              key={product.id}
              to={`/product/${product.id}`}
              className="w-[300px] flex-shrink-0 p-4 transition-all duration-300 transform hover:scale-105"
              style={{ height: "430px" }}
            >
              <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 overflow-hidden border border-gray-200 flex flex-col justify-between h-full">
                <img
                  className="w-full h-48 object-cover"
                  src={product.image[0]}
                  alt={product.title}
                />
                <div className="p-4 flex flex-col flex-grow">
                  <div className="flex items-center justify-between">
                    {product.out_of_stock ? (
                      <span className="text-red-600 text-xs font-semibold">
                        Out of Stock
                      </span>
                    ) : (
                      <span className="bg-red-600 text-white px-2 py-1 rounded text-xs font-semibold">
                        {product.discount}% off
                      </span>
                    )}
                    {!product.out_of_stock && (
                      <div className="flex items-baseline space-x-2 mt-2">
                        <p className="text-lime-900 font-semibold text-lg">
                          ₹{product.price}
                        </p>
                        <p className="text-gray-500 line-through text-sm">
                          ₹{Math.round(product.MRP)}
                        </p>
                      </div>
                    )}
                  </div>
                  <h5 className="text-gray-800 text-base font-semibold mt-2">
                    {product.title}
                  </h5>
                  <div className="flex items-center justify-center mt-auto space-x-4 pt-4">
                    {product.out_of_stock ? (
                      <button className="bg-red-600 text-white px-2 py-1 rounded-lg transition-colors shadow-lg text-sm no-underline">
                        Notify Me
                      </button>
                    ) : (
                      <>
                        <Link
                          to={`/product/${product.id}`}
                          className="bg-lime-600 hover:bg-lime-700 text-white px-2 py-1 rounded-lg transition-colors shadow-lg text-sm no-underline"
                        >
                          Buy Now
                        </Link>
                        <button
                          className="bg-lime-600 hover:bg-lime-700 text-white px-2 py-1 rounded-lg transition-colors shadow-lg text-sm no-underline"
                          onClick={(e) => {
                            e.preventDefault();
                            addProduct(product);
                          }}
                        >
                          Add to Cart
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>

        <button
          className="absolute right-0 z-10 top-1/2 transform -translate-y-1/2 bg-lime-600 text-white p-2 rounded-full shadow-lg flex items-center justify-center"
          onClick={scrollRight}
        >
          <span className="material-symbols-outlined">arrow_forward_ios</span>
        </button>
      </div>
    </>
  );

  const ShowAllProducts = () => {
    return (
      <>
        <div className="flex flex-wrap justify-center w-full">
          {filter.length !== 0 ? (
            filter.map((product) => {
              return (
                <Link
                  key={product.id}
                  to={`/product/${product.id}`}
                  className="w-11/12 md:w-[300px] flex-shrink-0 p-4 no-underline"
                  style={{ textDecoration: "none", height: "430px" }}
                >
                  <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 overflow-hidden border border-gray-200 flex flex-col justify-between h-full">
                    <img
                      className="w-full h-48 object-cover"
                      src={product.image[0]}
                      alt={product.title}
                    />
                    <div className="p-4 flex flex-col flex-grow">
                      <div className="flex items-center justify-between">
                        {product.out_of_stock ? (
                          <span className="text-red-600 text-xs font-semibold">
                            Out of Stock
                          </span>
                        ) : (
                          <span className="bg-red-600 text-white px-2 py-1 rounded text-xs font-semibold">
                            {product.discount}% off
                          </span>
                        )}
                        {!product.out_of_stock && (
                          <div className="flex items-baseline space-x-2 mt-2">
                            <p className="text-lime-900 font-semibold text-lg">
                              ₹{product.price}
                            </p>
                            <p className="text-gray-500 line-through text-sm">
                              ₹{Math.round(product.MRP)}
                            </p>
                          </div>
                        )}
                      </div>
                      <h5 className="text-gray-800 text-base font-semibold mt-2">
                        {product.title}
                      </h5>
                      <div className="flex items-center justify-center mt-auto space-x-4 pt-4">
                        {product.out_of_stock ? (
                          <button className="bg-red-600 text-white px-2 py-1 rounded-lg transition-colors shadow-lg text-sm no-underline">
                            Notify Me
                          </button>
                        ) : (
                          <>
                            <Link
                              to={`/product/${product.id}`}
                              className="bg-lime-600 hover:bg-lime-700 text-white px-2 py-1 rounded-lg transition-colors shadow-lg text-sm no-underline"
                            >
                              Buy Now
                            </Link>
                            <button
                              className="bg-lime-600 hover:bg-lime-700 text-white px-2 py-1 rounded-lg transition-colors shadow-lg text-sm no-underline"
                              onClick={(e) => {
                                e.preventDefault();
                                addProduct(product);
                              }}
                            >
                              Add to Cart
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })
          ) : (
            <div className="container my-3 py-3">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 py-5 bg-light text-center">
                    <h4 className="p-3 display-5">
                      No Product Found With This Category.
                    </h4>
                    <button
                      onClick={() => setFilter(data)}
                      className="btn btn-outline-dark mx-4"
                    >
                      <i className="fa fa-arrow-left"></i> Explore More Products
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  const scrollLeft = () => {
    const container = document.getElementById("latest-products");
    const scrollAmount =
      container.clientWidth / (window.innerWidth < 768 ? 1 : 2);
    container.scrollBy({
      left: -scrollAmount,
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    const container = document.getElementById("latest-products");
    const scrollAmount =
      container.clientWidth / (window.innerWidth < 768 ? 1 : 2);
    container.scrollBy({
      left: scrollAmount,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="container my-3 py-3">
        {/* Latest Products Heading */}
        <div className="row">
          <div className="col-12">
            <h2 className="text-4xl font-bold">Latest Products</h2>
            <hr className="my-4" />
          </div>
        </div>

        {/* Latest Products Section */}
        {loading ? <Loading /> : <ShowProducts />}

        {/* Categories Section */}
        <div className="row">
          <div className="col-12">
            <h2 className="text-4xl font-bold">Categories</h2>
            <hr className="my-4" />
          </div>
        </div>
        <div className="buttons text-center py-5">
          <button
            className="btn border-2 border-lime-300 hover:bg-lime-300 hover:text-lime-900 rounded-full w-fit  md:px-0 px-4 btn-sm m-2"
            onClick={() => setFilter(data)}
          >
            All
          </button>
          {categories.map((cat, index) => (
            <button
              key={index}
              className="btn border-2 border-lime-300 hover:bg-lime-300 hover:text-lime-900 rounded-full w-fit  md:px-0 px-4 btn-sm m-2"
              onClick={() => filterProduct(cat)}
            >
              {cat}
            </button>
          ))}
        </div>

        {/* All Products Section */}
        {loading ? <Loading /> : <ShowAllProducts />}
      </div>
    </>
  );
};

export default Products;
