import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import logo from "../components/assets/logo.png";
import cart from "../components/assets/cart.gif";
import user_acc from "../components/assets/user.gif";
import login from "../components/assets/login.gif";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Navbar = () => {
  const cartItems = useSelector((state) => state.cart) || [];
  const token = JSON.parse(localStorage.getItem("auth"))?.token;
  const [user, setUser] = React.useState(
    localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : {}
  );
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const dispatch = useDispatch();
  const menuRef = useRef(null);

  useEffect(() => {
    if (token) {
      axios.post(`${BASE_URL}/verifyUser`, { token }).then(
        (res) => {
          setUser(res.data);
        },
        () => {
          localStorage.removeItem("auth");
          setUser({});
        }
      );
    }
  }, [token]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close menu when clicking outside
  const handleOutsideClick = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isMenuOpen]);

  const totalQuantity = cartItems.reduce((acc, item) => acc + item.qty, 0);

  return (
    <nav
      className="bg-white shadow-md  sticky top-0"
      style={{ zIndex: "9999" }}
    >
      <div className="container mx-auto flex justify-between items-center px-4 md:px-8 relative py-4">
        {/* Logo Section */}
        <NavLink
          to="/"
          className="flex items-center space-x-2"
          onClick={() => window.scrollTo(0, 0)}
        >
          <img src={logo} alt="Logo" className="h-16 rounded-lg" />
        </NavLink>

        {/* Mobile Icons and Menu Button */}
        <div className="flex items-center space-x-4 md:hidden">
          {/* User Icon */}
          {Object.keys(user).length === 0 ? (
            <NavLink to="/login" className="text-gray-700">
              <span className="material-symbols-outlined text-2xl">login</span>
            </NavLink>
          ) : (
            <NavLink to="/user-dashboard" className="text-gray-700">
              <span className="material-symbols-outlined text-2xl">
                account_circle
              </span>
            </NavLink>
          )}
          {/* Cart Icon */}
          <NavLink to="/cart" className="text-gray-700 relative">
            <span className="material-symbols-outlined text-2xl">
              shopping_cart
            </span>
            <span className="absolute top-0 right-0 text-xs bg-red-500 text-white rounded-full px-1">
              {totalQuantity}
            </span>
          </NavLink>
          {/* Hamburger Menu Button */}
          <button
            className="text-gray-700 py-2 px-3 rounded focus:outline-none"
            onClick={toggleMenu}
            aria-label="Toggle menu"
          >
            <span className="material-symbols-outlined text-2xl">menu</span>
          </button>
        </div>

        {/* Desktop Nav Links */}
        <div className="hidden md:flex space-x-8 items-center">
          <NavLink
            className="text-gray-700 hover:text-gray-900 font-medium"
            to="/"
          >
            Home
          </NavLink>
          <NavLink
            className="text-gray-700 hover:text-gray-900 font-medium"
            to="/product"
          >
            Products
          </NavLink>
          <NavLink
            className="text-gray-700 hover:text-gray-900 font-medium"
            to="/about"
          >
            About
          </NavLink>
          <NavLink
            className="text-gray-700 hover:text-gray-900 font-medium"
            to="/contact"
          >
            Contact Us
          </NavLink>
        </div>

        {/* Desktop Cart & User Info */}
        <div className="hidden md:flex items-center space-x-6">
          {Object.keys(user).length === 0 ? (
            <NavLink
              to="/login"
              className="flex items-center space-x-2  px-4 py-2 rounded border border-black"
            >
              <img src={login} alt="Login" className="h-8" />
              <span className="font-semibold">Login</span>
            </NavLink>
          ) : (
            <NavLink
              to="/user-dashboard"
              className="flex items-center space-x-2 px-4 py-2 rounded border border-black"
            >
              <img src={user_acc} alt="User" className="h-8 rounded-full" />
              <div className="flex flex-col">
                <span className="text-xs">Hello,{user.name.split(" ")[0]}</span>
                <span className="font-semibold">Your Account</span>
              </div>
            </NavLink>
          )}
          <NavLink
            to="/cart"
            className="flex items-center space-x-2 px-4 py-2 rounded border border-black"
          >
            <img src={cart} alt="Cart" className="h-8" />
            <div className="flex flex-col">
              <span className="text-xs">Your Cart</span>
              <span className="font-semibold">({totalQuantity})</span>
            </div>
          </NavLink>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <>
          {/* Overlay for outside click */}
          <div
            className="fixed inset-0 bg-gray-800 bg-opacity-50 z-40"
            onClick={() => setIsMenuOpen(false)}
          ></div>

          <div
            ref={menuRef}
            className="fixed inset-y-0 left-0 bg-white flex flex-col justify-between w-64 h-full shadow-md p-4 z-50 transform transition-transform duration-300 md:hidden"
          >
            <div>
              <button
                className="text-gray-800 mb-4"
                onClick={toggleMenu}
                aria-label="Close menu"
              >
                <span className="material-symbols-outlined text-xl">close</span>
              </button>
              <nav className="space-y-4">
                <NavLink
                  className="block border-t border-b py-3 text-gray-700 hover:text-gray-900 transition-colors duration-200"
                  to="/"
                  onClick={toggleMenu}
                >
                  Home
                  <span className="material-symbols-outlined float-right">
                    home
                  </span>
                </NavLink>
                <NavLink
                  className="block border-t border-b py-3 text-gray-700 hover:text-gray-900 transition-colors duration-200"
                  to="/product"
                  onClick={toggleMenu}
                >
                  Products
                  <span className="material-symbols-outlined float-right">
                    inventory_2
                  </span>
                </NavLink>
                <NavLink
                  className="block border-t border-b py-3 text-gray-700 hover:text-gray-900 transition-colors duration-200"
                  to="/about"
                  onClick={toggleMenu}
                >
                  About
                  <span className="material-symbols-outlined float-right">
                    info
                  </span>
                </NavLink>
                <NavLink
                  className="block border-t border-b py-3 text-gray-700 hover:text-gray-900 transition-colors duration-200"
                  to="/contact"
                  onClick={toggleMenu}
                >
                  Contact Us
                  <span className="material-symbols-outlined float-right">
                    call
                  </span>
                </NavLink>
              </nav>
            </div>
            <div className="mt-6 space-y-4">
              {Object.keys(user).length === 0 ? (
                <NavLink
                  to="/login"
                  className="flex items-center space-x-2  px-4 py-2 rounded border border-black"
                >
                  <img src={login} alt="Login" className="h-8" />
                  <span className="font-semibold">Login</span>
                </NavLink>
              ) : (
                <NavLink
                  to="/user-dashboard"
                  className="flex items-center space-x-2 px-4 py-2 rounded border border-black"
                >
                  <img src={user_acc} alt="User" className="h-8 rounded-full" />
                  <div className="flex flex-col">
                    <span className="text-xs">
                      Hello,{user.name.split(" ")[0]}
                    </span>
                    <span className="font-semibold">Your Account</span>
                  </div>
                </NavLink>
              )}
              <NavLink
                to="/cart"
                className="flex items-center space-x-2 px-4 py-2 rounded border border-black"
              >
                <img src={cart} alt="Cart" className="h-8" />
                <div className="flex flex-col">
                  <span className="text-xs">Your Cart</span>
                  <span className="font-semibold">({totalQuantity})</span>
                </div>
              </NavLink>
            </div>
          </div>
        </>
      )}
    </nav>
  );
};

export default Navbar;
