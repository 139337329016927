import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, useParams } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { Footer, Navbar } from "../components";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { addItem } from "../redux/actions/cartActions";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Product = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [error, setError] = useState(null);
  const [quantity, setQuantity] = useState(1);

  const dispatch = useDispatch();

  const addProduct = (product, qty = 1) => {
    dispatch(addItem(product, qty));
    toast.dismiss();
    toast.success(`Added ${qty} item(s) to Cart`);
  };

  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true);
      setLoading2(true);
      try {
        const response = await axios.get(`${BASE_URL}/api/products/${id}`);
        const data = response.data;
        setProduct(data);
        setLoading(false);

        const response2 = await axios.get(
          `${BASE_URL}/api/category?type=${data.category}`
        );
        setSimilarProducts(response2.data ? response2.data : []);
        setLoading2(false);
      } catch (error) {
        setError("Failed to load product data. Please try again later.");
        setLoading(false);
        setLoading2(false);
      }
    };
    fetchProduct();
    window.scrollTo(0, 0);
  }, [id]);

  const Loading = () => (
    <div className="container my-5 py-2">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Skeleton height={400} width={400} />
        <div className="space-y-4">
          <Skeleton height={30} width={250} />
          <Skeleton height={90} />
          <Skeleton height={40} width={70} />
          <Skeleton height={50} width={110} />
          <Skeleton height={120} />
          <div className="flex space-x-4">
            <Skeleton height={40} width={110} />
            <Skeleton height={40} width={110} />
          </div>
        </div>
      </div>
    </div>
  );

  const ShowProduct = () => {
    const [selectedImage, setSelectedImage] = useState(
      Array.isArray(product.image) ? product.image[0] : product.image
    );

    const incrementQuantity = () => {
      if (quantity >= 20) {
        toast.dismiss();
        toast.error("Maximum 20 items allowed per order");
        return;
      }
      setQuantity((prevQuantity) => prevQuantity + 1);
    };

    const decrementQuantity = () => {
      setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
    };

    return (
      <div className="container my-5 py-2">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <img
              className="object-contain w-full h-96 rounded-md"
              src={selectedImage}
              alt={product.title}
            />
            {Array.isArray(product.image) && product.image.length > 1 && (
              <div className="flex space-x-2 mt-4 overflow-x-auto">
                {product.image.map((img, index) => (
                  <img
                    key={index}
                    src={img}
                    alt={`${product.title} - ${index + 1}`}
                    className={`w-20 h-20 object-contain rounded-md cursor-pointer border-2 ${
                      selectedImage === img
                        ? "border-lime-500"
                        : "border-transparent"
                    }`}
                    onClick={() => setSelectedImage(img)}
                  />
                ))}
              </div>
            )}
          </div>

          <div className="space-y-4">
            <h4 className="uppercase text-lime-500 font-semibold">
              {product.category}
            </h4>
            <h1 className="text-3xl font-bold">{product.title}</h1>
            <div className="flex items-center space-x-2">
              <div className="text-yellow-500 space-x-1">
                {Array(5)
                  .fill()
                  .map((_, i) => (
                    <i
                      key={i}
                      className={`fa fa-star ${
                        i < Math.round(5) ? "text-yellow-500" : "text-gray-300"
                      }`}
                    ></i>
                  ))}
              </div>
            </div>

            <span className="bg-red-600 text-white px-2 py-1 rounded text-xs font-semibold">
              {product.discount}% off
            </span>
            <div className="flex items-baseline space-x-2 mt-2">
              <p className="text-lime-950 font-semibold text-3xl">
                ₹{product.price}
              </p>
              <p className="text-gray-500 line-through text-sm">
                ₹{product.MRP}
              </p>
            </div>
            {product.description.map((desc, index) => (
              <p key={index} className="text-sm text-gray-600">
                {desc}
              </p>
            ))}

            <h1 className="text-2xl font-semibold">Key Benefits:</h1>
            <div className="flex mx-3 mt-4 flex-col items-start justify-start">
              <ul className="list-disc text-sm text-gray-600">
                {product.key_benefits.map((benefit, index) => (
                  <li key={index}>{benefit}</li>
                ))}
              </ul>
            </div>

            <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-3 mt-4">
              {product.out_of_stock ? (
                <>
                  <span className="text-red-600 text-sm font-semibold">
                    Out of Stock
                  </span>
                  <button className="bg-red-600 text-white px-4 py-2 rounded-md mt-2 sm:mt-0">
                    Notify Me
                  </button>
                </>
              ) : (
                <>
                  <label
                    htmlFor="quantity"
                    className="text-gray-700 mb-2 sm:mb-0"
                  >
                    Quantity:
                  </label>
                  <div className="flex items-center space-x-2">
                    <button
                      className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md"
                      onClick={decrementQuantity}
                    >
                      -
                    </button>
                    <span className="px-4 py-2">{quantity}</span>
                    <button
                      className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md"
                      onClick={incrementQuantity}
                    >
                      +
                    </button>
                  </div>

                  <button
                    className="bg-lime-500 text-white px-4 py-2 rounded-md mt-2 sm:mt-0 flex items-center space-x-2 justify-center"
                    onClick={() => addProduct(product, quantity)}
                  >
                    Add to Cart
                    <span className="material-symbols-outlined">
                      shopping_cart
                    </span>
                  </button>
                  <Link
                    to="/cart"
                    className="bg-lime-700 text-white px-4 py-2 rounded-md mt-2 sm:mt-0 flex items-center space-x-2 justify-center"
                  >
                    Go to Cart
                    <span className="material-symbols-outlined">
                      shopping_cart
                    </span>
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Loading2 = () => (
    <div className="my-4 py-4">
      <div className="flex space-x-4 overflow-auto">
        {[...Array(4)].map((_, index) => (
          <div key={index}>
            <Skeleton height={300} width={250} />
          </div>
        ))}
      </div>
    </div>
  );

  const ShowSimilarProduct = () => (
    <div className="py-4 my-4">
      <div
        className="flex space-x-6 overflow-auto scrollbar-hide"
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
      >
        {similarProducts.map((product) => (
          <Link
            key={product.id}
            to={`/product/${product.id}`}
            className="w-11/12 md:w-[300px] flex-shrink-0 p-4 no-underline"
            style={{ textDecoration: "none", height: "430px" }}
          >
            <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 overflow-hidden border border-gray-200 flex flex-col justify-between h-full">
              <img
                className="w-full h-48 object-cover"
                src={product.image[0]}
                alt={product.title}
              />
              <div className="p-4 flex flex-col flex-grow">
                <div className="flex items-center justify-between">
                  {product.out_of_stock ? (
                    <span className="text-red-600 text-xs font-semibold">
                      Out of Stock
                    </span>
                  ) : (
                    <span className="bg-red-600 text-white px-2 py-1 rounded text-xs font-semibold">
                      {product.discount}% off
                    </span>
                  )}
                  {!product.out_of_stock && (
                    <div className="flex items-baseline space-x-2 mt-2">
                      <p className="text-lime-900 font-semibold text-lg">
                        ₹{product.price}
                      </p>
                      <p className="text-gray-500 line-through text-sm">
                        ₹{Math.round(product.MRP)}
                      </p>
                    </div>
                  )}
                </div>
                <h5 className="text-gray-800 text-base font-semibold mt-2">
                  {product.title}
                </h5>
                <div className="flex items-center justify-center mt-auto space-x-4 pt-4">
                  {product.out_of_stock ? (
                    <button className="bg-red-600 text-white px-2 py-1 rounded-lg transition-colors shadow-lg text-sm no-underline">
                      Notify Me
                    </button>
                  ) : (
                    <>
                      <Link
                        to={`/product/${product.id}`}
                        className="bg-lime-600 hover:bg-lime-700 text-white px-2 py-1 rounded-lg transition-colors shadow-lg text-sm no-underline"
                      >
                        Buy Now
                      </Link>
                      <button
                        className="bg-lime-600 hover:bg-lime-700 text-white px-2 py-1 rounded-lg transition-colors shadow-lg text-sm no-underline"
                        onClick={(e) => {
                          e.preventDefault();
                          addProduct(product);
                        }}
                      >
                        Add to Cart
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );

  return (
    <>
      <Navbar />
      <div className="container">
        {error ? (
          <div className="text-center text-red-500 my-4">{error}</div>
        ) : (
          <>
            <div className="row">
              {loading ? <Loading /> : product && <ShowProduct />}
            </div>
            <div className="row my-5 py-5">
              <div className="hidden md:block">
                <h2 className="text-2xl font-bold mb-4">You may also Like</h2>
                {similarProducts.length > 4 ? (
                  <Marquee pauseOnHover={true} pauseOnClick={true} speed={50}>
                    {loading2 ? <Loading2 /> : <ShowSimilarProduct />}
                  </Marquee>
                ) : (
                  <div className="flex space-x-6 overflow-auto scrollbar-hide">
                    {loading2 ? <Loading2 /> : <ShowSimilarProduct />}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <Footer />
      <Toaster />
    </>
  );
};

export default Product;
